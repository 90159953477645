import { Heading } from "@components/ui/typograhpy/Heading"
import { theme } from "@config/theme"
import { CbHeroItem } from "@generated/models/CbHeroItem"
import { imgix } from "@lib/imgix"
import React, { FC, useEffect, useRef } from "react"
import * as styles from "./HeroItem.styles"

type HeroItemProps = CbHeroItem & {
  isActive: boolean
  isNext: boolean
}

export const HeroItem: FC<HeroItemProps> = ({
  title,
  desktop_image,
  mobile_image,
  isActive,
  isNext,
}) => {
  const isLoadedPreviously = useRef(false)
  const shouldLoadImage = isActive || isNext

  useEffect(() => {
    if (isActive || isNext) {
      isLoadedPreviously.current = true
    }
  }, [isActive, isNext])
  return (
    <div className={styles.container} data-active={isActive ? "" : undefined}>
      <div className={styles.content} data-active={isActive ? "" : undefined}>
        <Heading as="h2" variant="h1">
          {title.value}
        </Heading>
      </div>
      {shouldLoadImage && (
        <picture>
          <source
            srcSet={imgix(desktop_image.value[0].url, { width: 1920 })}
            media={`(min-width: ${theme.breakpoints.xlarge})`}
          ></source>
          <source
            srcSet={imgix(desktop_image.value[0].url, { width: 1440 })}
            media={`(min-width: ${theme.breakpoints.large})`}
          ></source>
          <source
            srcSet={imgix(desktop_image.value[0].url, { width: 1024 })}
            media={`(min-width: ${theme.breakpoints.medium})`}
          ></source>
          <source srcSet={imgix(mobile_image.value[0].url)}></source>
          <img
            className={styles.image}
            src={imgix(mobile_image.value[0].url)}
            alt={title.value}
          />
        </picture>
      )}
    </div>
  )
}
