import { theme } from "@config/theme"
import { CbCarouselItem } from "@generated/models/CbCarouselItem"
import { imgix } from "@lib/imgix"
import React, { FC, useEffect, useRef } from "react"
import * as styles from "./CarouselItem.styles"

type CarouselItemProps = CbCarouselItem & {
  isActive: boolean
  isNext: boolean
}

export const CarouselItem: FC<CarouselItemProps> = ({
  desktop_image,
  mobile_image,
  isActive,
  isNext,
}) => {
  const isLoadedPreviously = useRef(false)
  const shouldLoadImage = isActive || isNext || isLoadedPreviously.current

  useEffect(() => {
    if (isActive || isNext) {
      isLoadedPreviously.current = true
    }
  }, [isActive, isNext])
  return (
    <div className={styles.container}>
      {shouldLoadImage && (
        <picture>
          <source
            srcSet={imgix(desktop_image.value[0].url, { width: 1920 })}
            media={`(min-width: ${theme.breakpoints.xlarge})`}
          ></source>
          <source
            srcSet={imgix(desktop_image.value[0].url, { width: 1440 })}
            media={`(min-width: ${theme.breakpoints.large})`}
          ></source>
          <source
            srcSet={imgix(desktop_image.value[0].url, { width: 1024 })}
            media={`(min-width: ${theme.breakpoints.medium})`}
          ></source>
          <source srcSet={imgix(mobile_image.value[0].url)}></source>
          <img
            className={styles.image}
            src={imgix(mobile_image.value[0].url)}
            alt=""
          />
        </picture>
      )}
    </div>
  )
}
