import { parse } from "@config/theme"
import { css } from "linaria"

export const container = parse(
  {
    position: 'relative',
    width: '100%'
  }
)

export const video = parse({
  position: 'absolute',
  width: '100%',
  height: '100%'
}, css`
  top: 0;
  left: 0;
  outline: 0;
`)

