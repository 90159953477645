import { parse } from "@config/theme"
import { css } from "linaria"
import { rem } from "polished"

export const item = parse(
  {},
  css`
    grid-column: span 4;
    outline: 0;
    @media (hover: hover) and (pointer: fine) {
      &[data-link] {
        &:hover {
          [data-image-container] {
            background-color: #fff;
          }
          img {
            transform: scale(0.95, 0.95);
          }
        }
      }
    }
    &[data-link] {
      &:focus-visible {
        [data-image-container] {
          background-color: #fff;
        }
        img {
          transform: scale(0.95, 0.95);
        }
      }
    }
  `
)

export const imageContainer = parse(
  { position: "relative", width: "100%" },
  css`
    margin-bottom: ${rem(24)};
    &::before {
      content: "";
      display: block;
      padding-top: 75%;
    }
  `
)
export const image = parse(
  { position: "absolute" },
  css`
    transition: transform 0.25s ease-in-out;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
)

export const divider = parse(
  {
    bg: "white",
    position: "relative",
  },
  css`
    width: 80%;
    height: 3px;
    &::before {
      content: "";
      position: absolute;
      height: 11px;
      width: 25px;
      background: #fff;
      top: -5px;
    }
  `
)
