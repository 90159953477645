import { parse } from "@config/theme"
import { css } from "linaria"
import { rem } from "polished"

export const baseText = parse(
  {
    fontFamily: "openSouceSans",
  },
  css`
    line-height: 1.6;
    white-space: pre-line;
    b {
      font-weight: 500;
    }
  `
)
export const bodyRegular = css`
  font-size: ${rem(16)};
`
