import { parse, theme } from "@config/theme"
import { css } from "linaria"

export const container = parse(
  {
    position: "relative",
  },
  css`
    &::before {
      content: "";
      display: block;
      padding-top: 110%;
      @media screen and (min-width: ${theme.breakpoints.medium}) {
        padding-top: 55%;
      }
    }
  `
)

export const track = parse(
  { position: "absolute", width: "100%", height: "100%" },
  css`
    top: 0;
    left: 0;
    overflow-y: hidden;
  `
)
