import { Container } from "@components/ui/Container"
import { Text } from "@components/ui/typograhpy/Text"
import { Footer as FooterModel } from "@generated/models/Footer"
import { SingleItemResponse } from "@lib/kontent"
import React, { FC } from "react"
import { Logo } from "./logo"
import * as styles from "./styles"

type FooterProps = {
  footer: SingleItemResponse<FooterModel>
}

export const Footer: FC<FooterProps> = ({ footer }) => {
  return (
    <Container className={styles.container}>
      <div className={styles.flex}>
        <div className={styles.address}>
          <Text style={{ whiteSpace: "pre-line" }}>
            {footer.item.elements.address.value}
          </Text>
        </div>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.contact}>
          <div className={styles.contactDetails}>
            <a
              href={`tel:${footer.item.elements.phone_number.value}`}
              className={styles.link}
            >
              <Text>{footer.item.elements.phone_number.value}</Text>
            </a>
            <a
              href={`mailto:${footer.item.elements.email_address.value}`}
              className={styles.link}
            >
              <Text>{footer.item.elements.email_address.value}</Text>
            </a>
          </div>
          <a
            href={footer.item.elements.instagram_link.value}
            target="blank"
            className={styles.link}
          >
            <svg width="41" height="42" fill="none" viewBox="0 0 41 42">
              <path
                fill="currentColor"
                d="M20.258 10.747A10.37 10.37 0 009.871 21.134 10.37 10.37 0 0020.258 31.52a10.37 10.37 0 0010.387-10.387 10.37 10.37 0 00-10.387-10.387zm0 17.14c-3.715 0-6.753-3.029-6.753-6.753a6.76 6.76 0 016.753-6.753 6.76 6.76 0 016.753 6.753c0 3.724-3.037 6.753-6.753 6.753zm13.235-17.565a2.417 2.417 0 01-2.423 2.422 2.423 2.423 0 112.423-2.423zm6.88 2.459c-.154-3.246-.896-6.12-3.273-8.49-2.369-2.368-5.243-3.109-8.489-3.272-3.345-.19-13.37-.19-16.715 0-3.237.154-6.111.895-8.489 3.264C1.03 6.65.297 9.526.135 12.772c-.19 3.344-.19 13.37 0 16.715.153 3.245.895 6.12 3.272 8.489 2.378 2.368 5.243 3.11 8.489 3.272 3.345.19 13.37.19 16.715 0 3.246-.154 6.12-.895 8.489-3.272 2.368-2.369 3.11-5.244 3.272-8.49.19-3.344.19-13.36 0-16.705zM36.05 33.076a6.837 6.837 0 01-3.85 3.85c-2.668 1.059-8.996.814-11.943.814-2.947 0-9.284.236-11.942-.813a6.837 6.837 0 01-3.851-3.851c-1.058-2.667-.814-8.995-.814-11.942 0-2.947-.235-9.284.814-11.942a6.837 6.837 0 013.85-3.851c2.668-1.058 8.996-.814 11.943-.814 2.947 0 9.284-.235 11.942.814a6.837 6.837 0 013.851 3.85c1.058 2.667.814 8.996.814 11.943 0 2.947.244 9.284-.814 11.942z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </Container>
  )
}
