import { parse } from "@config/theme"
import { css } from "linaria"
import React, { FC } from "react"

type HeroIndicatorsProps = {
  length: number
  activeIndex: number
  shouldAnimate: boolean
}

export const HeroIndicators: FC<HeroIndicatorsProps> = ({
  length,
  activeIndex,
  shouldAnimate,
}) => {
  const items = []
  for (let i = 0; i < length; i++) {
    items.push(
      <div
        key={i}
        className={indicator}
        data-active={i === activeIndex && shouldAnimate ? "" : undefined}
      />
    )
  }
  return <div className={container}>{items}</div>
}

const container = parse(
  { position: "absolute", display: "grid", width: "100%" },
  css`
    bottom: 0.5rem;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    height: 3px;
    z-index: 10;
  `
)

const indicator = parse(
  { position: "relative" },
  css`
    height: 3px;
    background: #fff;
    &[data-active] {
      &::before {
        animation: slidein 5s linear;
      }
    }
    &::before {
      opacity: 0;
      content: "";
      position: absolute;
      height: 11px;
      width: 25px;
      background: #fff;
      top: -5px;
    }

    @keyframes slidein {
      0% {
        right: calc(100% - 25px);
        opacity: 0;
      }
      10% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        right: 0;
        opacity: 1;
      }
    }
  `
)
