import { parse, theme } from "@config/theme"
import { css } from "linaria"

export const container = parse({ position: "relative" })
export const content = parse(
  {
    bg: "shade900",
    position: { _: "relative", medium: "absolute" },
    py: { _: "32", medium: "40", xlarge: "56" },
    px: { _: "16", medium: "40", xlarge: "56" },
  },
  css`
    width: 100%;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      width: 52.5%;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
    }
    @media screen and (min-width: ${theme.breakpoints.xlarge}) {
      width: 47.5%;
      transform: translateY(-50%);
    }

    &[data-position="right"] {
      right: 0;
    }
  `
)
export const imageContainer = parse(
  { position: "relative" },
  css`
    width: 100%;
    &::before {
      padding-top: 78%;
      content: "";
      display: block;
      @media screen and (min-width: ${theme.breakpoints.medium}) {
        padding-top: 85%;
      }
      @media screen and (min-width: ${theme.breakpoints.xlarge}) {
        padding-top: 78%;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      width: 50%;
      &[data-position="right"] {
        margin-left: 50%;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.xlarge}) {
      width: 60%;
      &[data-position="right"] {
        margin-left: 40%;
      }
    }
  `
)
export const image = parse(
  { position: "absolute", width: "100%", height: "100%" },
  css`
    top: 0;
    object-fit: cover;
    object-position: center center;
  `
)
