import { Container, CONTENT_BLOCK_MB } from "@components/ui/Container"
import { CbHeroItem } from "@generated/models/CbHeroItem"
import React, { FC, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { useSwipeable } from "react-swipeable"
import { HeroIndicators } from "./HeroIndicators"
import { HeroItem } from "./HeroItem"
import { HeroNavigation } from "./HeroNavigation"
import * as styles from "./styles"

type HeroProps = {
  items: CbHeroItem[]
}

export const Hero: FC<HeroProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [innerRef, inView] = useInView()
  const timer = useRef<NodeJS.Timeout>()
  const [isMounted, setIsMounted] = useState(false)
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
  })

  useEffect(() => {
    setTimer()
  }, [activeIndex]) // eslint-disable-line

  useEffect(() => {
    setIsMounted(true)
    return () => clearTimeout(timer.current)
  }, [])

  useEffect(() => {
    if (inView && isMounted) {
      setTimer()
    } else {
      clearTimeout(timer.current)
    }
  }, [inView, isMounted]) // eslint-disable-line

  const slicedItems = !isMounted ? items.slice(0, 1) : items
  return (
    <>
      <Container mb={CONTENT_BLOCK_MB} ref={innerRef}>
        <div className={styles.container} {...handlers}>
          {slicedItems.map((item, index) => (
            <HeroItem
              key={index}
              {...item}
              isActive={index === activeIndex}
              isNext={index === activeIndex + 1}
            />
          ))}
          <HeroIndicators
            length={items.length}
            activeIndex={activeIndex}
            shouldAnimate={inView}
          />
          <HeroNavigation next={next} prev={prev} />
        </div>
      </Container>
    </>
  )
  function setTimer() {
    clearTimeout(timer.current)
    timer.current = setTimeout(next, 5000)
  }
  function prev() {
    setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1)
  }
  function next() {
    setActiveIndex(activeIndex < items.length - 1 ? activeIndex + 1 : 0)
  }
}
