import { imgix } from "@lib/imgix"
import React, { FC } from "react"
import { CbTextImageBoxed } from "__generated__/models/CbTextImageBoxed"
import { Container, CONTENT_BLOCK_MB } from "../Container"
import { Heading } from "../typograhpy/Heading"
import { Text } from "../typograhpy/Text"
import * as styles from "./TextAndImageBoxed.styles"
export const TextAndImageBoxed: FC<CbTextImageBoxed> = ({
  pre_title,
  title,
  image,
  text,
  order,
}) => {
  const imagePosition =
    order?.value[0]?.codename === "image_left___text_right" ? "left" : "right"
  const contentPosition = imagePosition === "left" ? "right" : "left"
  return (
    <Container mb={CONTENT_BLOCK_MB}>
      <div className={styles.container}>
        <div className={styles.imageContainer} data-position={imagePosition}>
          <img
            src={imgix(image.value[0].url)}
            alt={title.value}
            className={styles.image}
          />
        </div>
        <div className={styles.content} data-position={contentPosition}>
          {pre_title.value && (
            <Heading variant="h5" mb="24" color="shade700">
              {pre_title.value}
            </Heading>
          )}
          <Heading variant="h2" as="h2" mb="16">
            {title.value}
          </Heading>
          <Text>{text.value}</Text>
        </div>
      </div>
    </Container>
  )
}
