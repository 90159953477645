import { Container, CONTENT_BLOCK_MB } from "@components/ui/Container"
import { Heading } from "@components/ui/typograhpy/Heading"
import Link from "next/link"
import React, { FC } from "react"
import { CbColumnsItem } from "@generated/models/CbColumnsItem"
import * as styles from "./ThreeColImages.styles"
import { imgix } from "@lib/imgix"
type ThreeColImagesProps = {
  items: CbColumnsItem[]
}

export const ThreeColImages: FC<ThreeColImagesProps> = ({ items }) => {
  return (
    <Container variant="grid" mb={CONTENT_BLOCK_MB}>
      {items.map((item) => {
        const inner = (
          <>
            <div className={styles.imageContainer} data-image-container="">
              <img
                className={styles.image}
                src={imgix(item.image.value[0].url)}
                width={750}
                height={525}
                loading="lazy"
                alt={item.title.value}
              />
            </div>
            <Heading variant="h4" mb="24">
              {item.title.value}
            </Heading>
            <div className={styles.divider}></div>
          </>
        )
        if (item.link_url.value) {
          return (
            <Link href={item.link_url.value} passHref key={item.title.value}>
              <a className={styles.item} data-link="" title={item.title.value}>
                {inner}
              </a>
            </Link>
          )
        }
        if (item.external_link_url__optional_.value) {
          return (
            <a
              className={styles.item}
              data-link=""
              title={item.title.value}
              href={item.external_link_url__optional_.value}
              target="blank"
              key={item.title.value}
            >
              {inner}
            </a>
          )
        }
        return (
          <div className={styles.item} key={item.title.value}>
            {inner}
          </div>
        )
      })}
    </Container>
  )
}
