import { theme, parse } from "@config/theme"
import { css } from "linaria"
import { rem } from "polished"

export const container = parse(
  {
    // position: "absolute",
    width: "100%",
    height: "100%",
  },
  css`
    transition: opacity 0.5s ease-in-out 0.2s;
    top: 0;
    min-width: 100%;
    scroll-snap-align: start;
  `
)

export const image = parse(
  { width: "100%", height: "100%" },
  css`
    object-fit: cover;
    object-position: center center;
  `
)

export const content = parse(
  { position: "absolute" },
  css`
    transition: opacity 0.7s ease-in-out;
    z-index: 10;
    max-width: ${rem(300)};
    bottom: 9rem;
    opacity: 0;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      max-width: ${rem(350)};
    }
    &[data-active] {
      opacity: 1;
    }
  `
)
