import { parseAll, Theme } from "@config/theme"
import { cx } from "linaria"
import React, { FC, HTMLAttributes } from "react"
import { ThemeSystemProps } from "theme-system"
import * as styles from "./Text.styles"

type TextProps = HTMLAttributes<HTMLElement> &
  Pick<ThemeSystemProps<Theme>, "mb" | "color" | "fontWeight"> & {
    variant?: "regular"
    as?: "p" | "div" | "span"
  }

export const Text: FC<TextProps> = ({
  color = "white",
  as = "div",
  variant = "regular",
  className,
  ...rest
}) => {
  const Comp = as
  const key = `body${variant.charAt(0).toUpperCase() + variant.slice(1)}`
  return (
    <Comp
      data-variant={variant}
      className={cx(
        className,
        parseAll({ color, ...rest }),
        styles.baseText,
        styles[key]
      )}
      {...rest}
    />
  )
}
