import { theme, parse } from "@config/theme"
import { css } from "linaria"
import { rem } from "polished"

export const container = parse(
  { position: "relative", pb: { medium: "56" } },
  css``
)
export const imageContainer = parse(
  { position: "relative", width: "100%" },
  css`
    &::before {
      padding-top: 70%;
      content: "";
      display: block;
      @media screen and (min-width: ${theme.breakpoints.medium}) {
        padding-top: 50%;
      }
    }
  `
)
export const image = parse(
  { position: "absolute", width: "100%", height: "100%" },
  css`
    top: 0;
    object-fit: cover;
    object-position: center center;
  `
)
export const contentContainer = parse(
  {
    bg: "shade900",
    py: { _: "32", medium: "40" },
    px: { _: "16", medium: "40" },
    position: { medium: "absolute" },
  },
  css`
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      z-index: 2;
      max-width: ${rem(432)};
      bottom: 0;
      &[data-position="left"] {
        left: 3rem;
      }
      &[data-position="right"] {
        right: 3rem;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.xlarge}) {
      max-width: ${rem(528)};
      &[data-position="left"] {
        left: 5rem;
      }
      &[data-position="right"] {
        right: 5rem;
      }
    }
  `
)
