import { IconChevronLeft } from "@components/ui/icons/IconChevronLeft"
import React, { FC } from "react"
import { parse } from "@config/theme"
import { css } from "linaria"
import { IconChevronRight } from "@components/ui/icons/IconChevronRight"

type CarouselNavigationProps = {
  next: () => void
  prev: () => void
  canMoveLeft: boolean
  canMoveRight: boolean
}

export const CarouselNavigation: FC<CarouselNavigationProps> = ({
  next,
  prev,
  canMoveLeft,
  canMoveRight,
}) => {
  return (
    <div className={container}>
      <button className={button} onClick={prev} disabled={!canMoveLeft}>
        <IconChevronLeft size={32} />
      </button>
      <button className={button} onClick={next} disabled={!canMoveRight}>
        <IconChevronRight size={32} />
      </button>
    </div>
  )
}

const container = parse(
  { position: "absolute", display: { _: "none", medium: "flex" } },
  css`
    right: 0;
    z-index: 10;
    bottom: 0.5rem;
    color: #fff;
    outline: 0;
    button {
      margin-left: 0.5rem;
    }
  `
)

const button = parse(
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  css`
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:focus-within {
      outline: 1px #fff solid;
    }
  `
)
