import { parse, theme } from "@config/theme"
import { css } from "linaria"

export const container = parse(
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bg: "shade900",
  },
  css`
    flex-direction: column;
    padding: 2rem 1rem;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      padding: 6rem 3rem;
    }
  `
)

export const buttonContainer = parse(
  {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: { medium: "center" },
  },
  css``
)
export const link = parse(
  {
    width: { _: "100%", medium: "auto" },
    display: "block",
    fontFamily: "artegra",
    fontWeight: "medium",
    color: "white",
    textAlign: "center",
    px: { medium: "48" },
    mx: { medium: "8" },
    mb: { _: "16", medium: "0" },
  },
  css`
    transition: background 0.2s ease-in-out;
    height: 3rem;
    line-height: 3rem;
    border: 2px solid #fff;
    outline: 0;
    &:focus-visible {
      background: rgba(255, 255, 255, 0.1);
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  `
)
