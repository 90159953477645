import { RichContentPage } from "@generated/models/RichContentPage"
import { Homepage } from "@generated/models/Homepage"
import React, { FC } from "react"
import { Contact } from "./Contact"
import { Hero } from "./Hero"
import { ImageBannerWithText } from "./ImageBannerText"
import { TextAndImageBoxed } from "./TextAndImageBoxed"
import { TextAndText } from "./TextAndText"
import { ThreeColImages } from "./ThreeColImages"
import { Video } from "./Video"
import { Carousel } from "./Carousel"

type BlocksProps = {
  content: RichContentPage | Homepage
  modular_content: { [key: string]: any }
}

export const Blocks: FC<BlocksProps> = ({ content, modular_content }) => {
  return (
    <>
      {content.items.value.map((key) => {
        const item = modular_content[key]
        if (item.system.type === "cb___images__with_or_without_link__grid") {
          const items = item.elements.items.value.map(
            (key) => modular_content[key].elements
          )
          return <ThreeColImages key={key} items={items} />
        }
        if (item.system.type === "cb___text___image_boxed") {
          return <TextAndImageBoxed key={key} {...item.elements} />
        }
        if (item.system.type === "cb___text___text") {
          return <TextAndText key={key} {...item.elements} />
        }
        if (item.system.type === "cb___image_banner___text") {
          return <ImageBannerWithText key={key} {...item.elements} />
        }
        if (item.system.type === "cb___contact") {
          return <Contact key={key} {...item.elements} />
        }

        if (item.system.type === "cb___video") {
          return <Video key={key} {...item.elements} />
        }
        if (item.system.type === "cb___hero") {
          return (
            <Hero
              key={key}
              items={item.elements.items.value.map(
                (key) => modular_content[key].elements
              )}
            />
          )
        }
        if (item.system.type === "cb___carousel") {
          return (
            <Carousel
              key={key}
              items={item.elements.items.value.map(
                (key) => modular_content[key].elements
              )}
            />
          )
        }
        return null
      })}
    </>
  )
}
