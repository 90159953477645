import { parse, parseAll, theme, Theme } from "@config/theme"
import { css, cx } from "linaria"
import { rem } from "polished"
import React, { HTMLAttributes } from "react"
import { ThemeSystemProps } from "theme-system"

export const CONTENT_BLOCK_MB: ThemeSystemProps<Theme>["mb"] = {
  _: "64",
  medium: "112",
  xlarge: "160",
}

type ContainerProps = Pick<ThemeSystemProps<Theme>, "mb" | "mt"> &
  HTMLAttributes<HTMLDivElement> & {
    as?: "div" | "section"
    variant?: "default" | "grid"
  }

export const Container = React.forwardRef<HTMLElement, ContainerProps>(
  (
    { mb, mt, className, as = "section", variant = "default", ...rest },
    ref
  ) => {
    const Comp = as
    return (
      <Comp
        className={cx(className, container, parseAll({ mb, mt }))}
        data-grid={variant === "grid" ? "" : undefined}
        ref={ref as any}
        {...rest}
      ></Comp>
    )
  }
)

const container = parse(
  {
    position: "relative",
    width: "100%",
    mx: "auto",
    px: {
      _: "20",
      medium: "40",
      large: "160",
    },
  },
  css`
    max-width: ${rem(1568)};
    &[data-grid] {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: ${rem(24)};
      grid-row-gap: ${rem(24)};
      @media screen and (min-width: ${theme.breakpoints.medium}) {
        grid-template-columns: repeat(12, 1fr);
        grid-row-gap: ${rem(20)};
      }
    }
  `
)
