import React, { FC, HTMLAttributes } from "react"

export type IconProps = HTMLAttributes<SVGElement> & {
  size?: number
  fill?: string
}

export const Icon: FC<IconProps> = ({ size = "100%", ...rest }) => {
  return (
    <svg
      vectorEffect="non-scaling-size"
      viewBox="0 0 32 32"
      fill="none"
      width={size}
      height={size}
      {...rest}
    />
  )
}
