import { Navigation } from "@generated/models/Navigation"
import { SingleItemResponse } from "@lib/kontent"
import { usePresence } from "framer-motion"
import Link from "next/link"
import React, { FC, useEffect } from "react"
import { RemoveScroll } from "react-remove-scroll"
import * as styles from "./MobileNav.styles"
export const MobileNav: FC<SingleItemResponse<Navigation>> = ({
  item,
  modular_content,
}) => {
  const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    !isPresent && setTimeout(safeToRemove, 200)
  }, [isPresent, safeToRemove])

  return (
    <RemoveScroll>
      <div
        className={styles.container}
        data-present={isPresent ? "" : undefined}
      >
        <nav className={styles.nav}>
          {item.elements.left_navigation_items.value.map((key) => (
            <Link
              href={`/${modular_content[key].elements.url_slug.value}`}
              key={key}
            >
              <a className={styles.navLink}>
                {modular_content[key].elements.url_slug.value}
              </a>
            </Link>
          ))}
          {item.elements.right_navigation_items.value.map((key) => (
            <Link
              href={`/${modular_content[key].elements.url_slug.value}`}
              key={key}
            >
              <a className={styles.navLink}>
                {modular_content[key].elements.url_slug.value}
              </a>
            </Link>
          ))}
        </nav>
      </div>
    </RemoveScroll>
  )
}
