import { Footer as FooterModel } from "@generated/models/Footer"
import { Navigation } from "@generated/models/Navigation"
import { SingleItemResponse } from "@lib/kontent"
import React, { FC } from "react"
import { Footer } from "./footer"
import { PageHeader } from "./page-header"
import * as Elements from "@generated/models/Elements"
import { NextSeo } from "next-seo"
import { imgix } from "@lib/imgix"
type PageProps = {
  navigation: SingleItemResponse<Navigation>
  footer: SingleItemResponse<FooterModel>
  seo: SEOProps
}

export const Page: FC<PageProps> = ({ navigation, footer, children, seo }) => {
  return (
    <>
      <NextSeo
        title={seo.seo_metadata__meta_title.value}
        description={seo.seo_metadata__meta_description.value}
        openGraph={{
          title: seo.seo_metadata__meta_og_title.value,
          description: seo.seo_metadata__meta_og_description.value,
          images: seo.seo_metadata__meta_og_image?.value?.[0]
            ? [
                {
                  url: imgix(seo.seo_metadata__meta_og_image.value[0].url),
                  width: seo.seo_metadata__meta_og_image.value[0].width,
                  height: seo.seo_metadata__meta_og_image.value[0].height,
                },
              ]
            : [],
        }}
      />
      <PageHeader navigation={navigation} />
      {children}
      <Footer footer={footer} />
    </>
  )
}

type SEOProps = {
  seo_metadata__meta_description: Elements.TextElement
  seo_metadata__meta_og_description: Elements.TextElement
  seo_metadata__meta_og_image: Elements.AssetsElement
  seo_metadata__meta_title: Elements.TextElement
  seo_metadata__meta_og_title: Elements.TextElement
}

export const pickSeoProps = (props: SEOProps): SEOProps => {
  const {
    seo_metadata__meta_description,
    seo_metadata__meta_og_description,
    seo_metadata__meta_og_image,
    seo_metadata__meta_title,
    seo_metadata__meta_og_title,
  } = props

  return {
    seo_metadata__meta_description,
    seo_metadata__meta_og_description,
    seo_metadata__meta_og_image,
    seo_metadata__meta_title,
    seo_metadata__meta_og_title,
  }
}
