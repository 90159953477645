import { Container } from "@components/ui/Container"
import { Navigation } from "@generated/models/Navigation"
import { SingleItemResponse } from "@lib/kontent"
import { AnimatePresence } from "framer-motion"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { FC, useEffect, useState } from "react"
import { MobileNav } from "./MobileNav"
import * as styles from "./styles"

type PageHeaderProps = {
  navigation: SingleItemResponse<Navigation>
}

export const PageHeader: FC<PageHeaderProps> = ({
  navigation: { item, modular_content },
}) => {
  const { asPath } = useRouter()
  const [mobileNavActive, setMobileNavActive] = useState(false)
  useEffect(() => {
    setMobileNavActive(false)
  }, [asPath])
  return (
    <>
      <div className={styles.spacer}></div>
      <Container
        className={styles.container}
        data-mobile-nav-active={mobileNavActive ? "" : undefined}
      >
        <div className={styles.grid}>
          <div className={styles.logoContainer}>
            <Link href="/" passHref>
              <a title="Home">
                <img src="/images/logo.svg" className={styles.logo} alt="" />
              </a>
            </Link>
          </div>
          <div className={styles.toggleButtonContainer}>
            <button
              className={styles.toggleButton}
              onClick={() => setMobileNavActive(!mobileNavActive)}
            >
              {mobileNavActive ? "CLOSE" : "MENU"}
            </button>
          </div>
          <div className={styles.navLeft}>
            {item.elements.left_navigation_items.value.map((key) => (
              <Link
                href={`/${modular_content[key].elements.url_slug.value}`}
                key={key}
              >
                <a
                  className={styles.navLink}
                  data-active={
                    asPath ===
                    `/${modular_content[key].elements.url_slug.value}`
                      ? ""
                      : undefined
                  }
                >
                  {modular_content[key].elements.url_slug.value}
                </a>
              </Link>
            ))}
          </div>
          <div className={styles.navRight}>
            {item.elements.right_navigation_items.value.map((key) => (
              <Link
                href={`/${modular_content[key].elements.url_slug.value}`}
                key={key}
              >
                <a
                  className={styles.navLink}
                  data-active={
                    asPath ===
                    `/${modular_content[key].elements.url_slug.value}`
                      ? ""
                      : undefined
                  }
                >
                  {modular_content[key].elements.url_slug.value}
                </a>
              </Link>
            ))}
          </div>
        </div>
      </Container>
      <AnimatePresence>
        {mobileNavActive && (
          <MobileNav item={item} modular_content={modular_content} />
        )}
      </AnimatePresence>
    </>
  )
}
