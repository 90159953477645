import { Container, CONTENT_BLOCK_MB } from "@components/ui/Container"
import { trackClassName, useSlider } from "@components/ui/use-slider"
import { CbCarouselItem } from "@generated/models/CbCarouselItem"
import { cx } from "linaria"
import React, { FC, useRef } from "react"
import { CarouselIndicator } from "./CarouselIndicator"
import { CarouselItem } from "./CarouselItem"
import { CarouselNavigation } from "./CarouselNavigation"
import * as styles from "./styles"

type CarouselProps = {
  items: CbCarouselItem[]
}

export const Carousel: FC<CarouselProps> = ({ items }) => {
  const trackRef = useRef()
  const {
    next,
    prev,
    onTouchEnd,
    canMoveLeft,
    canMoveRight,
    index,
  } = useSlider({ trackRef })
  return (
    <>
      <Container mb={CONTENT_BLOCK_MB}>
        <div className={cx(styles.container)}>
          <div
            className={cx(trackClassName, styles.track)}
            ref={trackRef}
            onTouchEnd={onTouchEnd}
          >
            {items.map((item, i) => (
              <CarouselItem
                key={i}
                {...item}
                isActive={i === index}
                isNext={i === index + 1}
              />
            ))}
          </div>
          <CarouselIndicator activeIndex={index} length={items.length} />
          <CarouselNavigation
            next={next}
            prev={prev}
            canMoveLeft={canMoveLeft}
            canMoveRight={canMoveRight}
          />
        </div>
      </Container>
    </>
  )
}
