import { parse, theme } from "@config/theme"
import { CbTextText } from "@generated/models/CbTextText"
import { css } from "linaria"
import React, { FC } from "react"
import { Container, CONTENT_BLOCK_MB } from "../Container"
import { Heading } from "../typograhpy/Heading"
import { Text } from "../typograhpy/Text"

export const TextAndText: FC<CbTextText> = ({ title, text }) => {
  return (
    <Container mb={CONTENT_BLOCK_MB}>
      <div className={container}>
        <div className={titleContainer}>
          <Heading variant="h2" as="h2">
            {title.value}
          </Heading>
        </div>
        <div className={contentContainer}>
          <Text>{text.value}</Text>
        </div>
      </div>
    </Container>
  )
}

const container = parse(
  { display: "flex", flexWrap: "wrap", justifyContent: "space-between" },
  css`
    @media screen and (min-width: ${theme.breakpoints.large}) {
      width: 90%;
      margin: 0 auto;
    }
  `
)
const titleContainer = parse(
  { textAlign: { _: "left", medium: "right" } },
  css`
    width: 100%;
    margin-bottom: 1.5rem;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      width: 40%;
      margin-bottom: 0;
    }
    @media screen and (min-width: ${theme.breakpoints.large}) {
      width: 30%;
    }
  `
)
const contentContainer = parse(
  {},
  css`
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      width: calc(60% - 1.5rem);
    }
    @media screen and (min-width: ${theme.breakpoints.large}) {
      width: calc(70% - 2.5rem);
    }
  `
)
