import { parse, theme } from "@config/theme"
import { css } from "linaria"
import { rem } from "polished"

export const container = parse(
  {
    position: "fixed",
    height: "screen",
    width: "screen",
    bg: "black",
  },
  css`
    top: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    z-index: 99;
    padding-top: 5rem;
    &[data-present] {
      opacity: 1;
    }
  `
)

export const nav = parse(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
  },
  css`
    margin-top: 4rem;
    flex-direction: column;
  `
)

export const navLink = parse(
  {
    fontFamily: "artegra",
    fontWeight: "medium",
    position: "relative",
    mb: "32",
  },
  css`
    text-transform: uppercase;
    letter-spacing: 1px;
    outline: 0;
    letter-spacing: 1.5px;
    font-size: ${rem(30)};
    font-weight: 700;
    @media screen and (min-width: ${theme.breakpoints.large}) {
      font-size: ${rem(30)};
    }
  `
)
