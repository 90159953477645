import { parse, theme } from "@config/theme"
import { css } from "linaria"

export const container = parse(
  {
    position: "relative",
  },
  css`
    &::before {
      content: "";
      display: block;
      padding-top: 170%;
      @media screen and (min-width: ${theme.breakpoints.medium}) {
        padding-top: 55%;
      }
    }
  `
)
