import { rem } from "polished"
import { createThemeSystem } from "theme-system"

export const theme = {
  breakpoints: {
    // small: "40em", // 640px
    medium: "48em", // 768px
    large: "64em", // 1024px
    xlarge: "90em", // 1440px
  },
  fontSizes: {},
  fontWeights: {
    regular: "400",
    medium: "600",
    black: "700",
  },
  fontFamilies: {
    artegra: "Artegra Sans, sans-serif",
    openSouceSans: "Open Sauce Sans, sans-serif",
  },
  space: {
    "0": "0",
    "4": rem(4),
    "8": rem(8),
    "12": rem(12),
    "16": rem(16),
    "20": rem(20),
    "24": rem(24),
    "32": rem(32),
    "40": rem(40),
    "48": rem(48),
    "56": rem(56),
    "64": rem(64),
    "80": rem(80),
    "112": rem(112),
    "160": rem(160),
    auto: "auto",
  },
  colors: {
    black: "#000",
    white: "#FFF",
    shade700: "#9C9C9C",
    shade900: "#191919",
  },
}

export type Theme = typeof theme

export const { parse, parseAll, utilities } = createThemeSystem<Theme>(theme)
