import { IconChevronLeft } from "@components/ui/icons/IconChevronLeft"
import React, { FC } from "react"
import { parse } from "@config/theme"
import { css } from "linaria"
import { IconChevronRight } from "@components/ui/icons/IconChevronRight"

type HeroNavigationProps = {
  next: () => void
  prev: () => void
}

export const HeroNavigation: FC<HeroNavigationProps> = ({ next, prev }) => {
  return (
    <div className={container}>
      <button className={button} onClick={prev}>
        <IconChevronLeft size={32} />
      </button>
      <button className={button} onClick={next}>
        <IconChevronRight size={32} />
      </button>
    </div>
  )
}

const container = parse(
  { position: "absolute", display: "flex" },
  css`
    right: 0;
    z-index: 10;
    bottom: 3rem;
    color: #fff;
    outline: 0;
    button {
      margin-left: 0.5rem;
    }
  `
)

const button = parse(
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  css`
    &:focus-within {
      outline: 1px #fff solid;
    }
  `
)
