import { Heading } from "@components/ui/typograhpy/Heading"
import { parse } from "@config/theme"
import { css } from "linaria"
import React, { FC } from "react"

type CarouselIndicatorProps = {
  length: number
  activeIndex: number
}

export const CarouselIndicator: FC<CarouselIndicatorProps> = ({
  length,
  activeIndex,
}) => {
  return (
    <Heading className={container} variant="h5">
      {activeIndex + 1} / {length}
    </Heading>
  )
}

const container = parse(
  { position: "absolute", display: "grid", width: "100%" },
  css`
    bottom: 0.75rem;
    z-index: 10;
    padding-left: 0.5rem;
  `
)
