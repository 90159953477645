import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconChevronRight: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path d="m3.97266 2.9873h27.0276v27.0276h-27.0276z" />
      <path
        d="m2.12132 16.1767h18.4631v18.4631h-18.4631z"
        stroke="currentColor"
        strokeWidth="3"
        transform="matrix(.70710678 -.70710678 .70710678 .70710678 -10.817334 6.238045)"
      />
      <path
        d="m13.6484 21.3886 5.1578-5.169-5.1578-5.1691 1.5879-1.58782 6.7569 6.75692-6.7569 6.7569z"
        fill="currentColor"
      />
    </Icon>
  )
}
