import { CbVideo } from "@generated/models/CbVideo"
import React, { FC } from "react"
import { Container, CONTENT_BLOCK_MB } from "../Container"
import * as styles from "./Video.styles"

export const Video: FC<CbVideo> = ({
  preview_image,
  video,
  video_height,
  video_width,
}) => {
  const ratio = video_height.value / video_width.value
  return (
    <Container mb={CONTENT_BLOCK_MB}>
      <div
        className={styles.container}
        style={{ paddingTop: `${ratio * 100}%` }}
      >
        <video
          controls
          preload="none"
          poster={preview_image.value[0].url}
          className={styles.video}
          src={video.value[0].url}
          controlsList="nodownload"
          disablePictureInPicture
        />
      </div>
    </Container>
  )
}
