import { theme, parse } from "@config/theme"
import { css } from "linaria"
import { rem } from "polished"

export const spacer = css`
  height: 3.5rem;
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    height: 0;
  }
`
export const container = parse(
  { bg: "black", position: { _: "fixed", medium: "relative" } },
  css`
    z-index: 100;
    top: 0;
    &[data-mobile-nav-active] {
      position: absolute;
    }
  `
)
export const grid = parse(
  {
    display: "grid",
    pt: { _: "12", medium: "16", large: "20" },
    pb: { _: "8", medium: "16", large: "20" },
  },
  css`
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
  `
)
export const logoContainer = parse(
  {
    display: { medium: "flex" },
    alignItems: "center",
    justifyContent: "center",
  },
  css`
    grid-column: span 3;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      grid-column: span 6;
    }
    @media screen and (min-width: ${theme.breakpoints.large}) {
      grid-column: span 2;
      order: 2;
    }
  `
)

export const logo = css`
  width: 100%;
  height: auto;
  max-width: 17.5rem;
`
export const navLeft = parse(
  {
    display: { _: "none", medium: "flex" },
    alignItems: "center",
    justifyContent: { medium: "flex-end", large: "flex-start" },
  },
  css`
    grid-column: span 3;
    margin-top: 1rem;
    @media screen and (min-width: ${theme.breakpoints.large}) {
      margin-top: 0;
      grid-column: span 2;
      order: 1;
    }
    a {
      @media screen and (min-width: ${theme.breakpoints.medium}) {
        margin: 0 0.75rem;
      }
      @media screen and (min-width: ${theme.breakpoints.large}) {
        margin-right: 1.25rem;
        margin-left: 0;
      }
    }
  `
)
export const navRight = parse(
  {
    display: { _: "none", medium: "flex" },
    alignItems: "center",
    justifyContent: { medium: "flex-start", large: "flex-end" },
  },
  css`
    grid-column: span 3;
    margin-top: 1rem;
    @media screen and (min-width: ${theme.breakpoints.large}) {
      margin-top: 0;
      grid-column: span 2;
      order: 3;
    }
    a {
      @media screen and (min-width: ${theme.breakpoints.medium}) {
        margin: 0 0.75rem;
      }
      @media screen and (min-width: ${theme.breakpoints.large}) {
        margin-right: 0;
        margin-left: 1.25rem;
      }
    }
  `
)

export const navLink = parse(
  { fontFamily: "artegra", fontWeight: "medium", position: "relative" },
  css`
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: ${rem(14)};
    outline: 0;
    &:hover,
    &:focus-visible,
    &[data-active] {
      &::after {
        width: 100%;
      }
    }
    &::after {
      transition: width 0.2s ease-in-out;
      content: "";
      height: 2px;
      background: #fff;
      width: 0%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  `
)

export const toggleButton = parse(
  { fontFamily: "artegra", fontWeight: "medium", position: "relative" },
  css`
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: ${rem(14)};
    outline: 0;
  `
)
export const toggleButtonContainer = parse(
  {
    display: { _: "flex", medium: "none" },
    alignItems: "center",
    justifyContent: "flex-end",
  },
  css`
    grid-column: span 3;
  `
)
