import { css } from "linaria"

export const trackClassName = css`
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  display: flex;

  /** Disallow swiping on non-touch devices */
  @media (hover: hover) and (pointer: fine) {
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
`
