import { theme } from "@config/theme"
import { CbImageBannerText } from "@generated/models/CbImageBannerText"
import { imgix } from "@lib/imgix"
import React, { FC } from "react"
import { Container, CONTENT_BLOCK_MB } from "../Container"
import { Heading } from "../typograhpy/Heading"
import { Text } from "../typograhpy/Text"
import * as styles from "./ImageBannerText.styles"

export const ImageBannerWithText: FC<CbImageBannerText> = ({
  pre_title,
  title,
  text,
  mobile_image,
  desktop_image,
  content_position,
}) => {
  if (!mobile_image.value[0] || !desktop_image.value[0]) {
    return null
  }
  const position =
    content_position.value?.[0]?.codename === "right" ? "right" : "left"
  return (
    <Container mb={CONTENT_BLOCK_MB}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <picture>
            <source
              srcSet={imgix(desktop_image.value[0].url, { width: 1920 })}
              media={`(min-width: ${theme.breakpoints.xlarge})`}
            ></source>
            <source
              srcSet={imgix(desktop_image.value[0].url, { width: 1440 })}
              media={`(min-width: ${theme.breakpoints.large})`}
            ></source>
            <source
              srcSet={imgix(desktop_image.value[0].url, { width: 1024 })}
              media={`(min-width: ${theme.breakpoints.medium})`}
            ></source>
            <source srcSet={imgix(mobile_image.value[0].url)}></source>
            <img
              className={styles.image}
              src={imgix(mobile_image.value[0].url)}
              alt={title.value}
              loading="lazy"
            />
          </picture>
        </div>
        <div className={styles.contentContainer} data-position={position}>
          <Heading variant="h5" color="shade700" mb="16">
            {pre_title.value}
          </Heading>
          <Heading variant="h2" mb="16">
            {title.value}
          </Heading>
          <Text>{text.value}</Text>
        </div>
      </div>
    </Container>
  )
}
