import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconChevronLeft: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <rect
        x="-2.12132"
        width="18.4631"
        height="18.4631"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 28.3787 14.6767)"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M18.3516 21.3886L13.1938 16.2196L18.3516 11.0505L16.7637 9.46268L10.0068 16.2196L16.7637 22.9765L18.3516 21.3886Z"
        fill="currentColor"
      />
    </Icon>
  )
}
