import { theme, parse } from "@config/theme"
import { css } from "linaria"
import { rem } from "polished"

export const styles = parse(
  {
    fontFamily: "artegra",
  },
  css`
    text-transform: uppercase;
    line-height: 1.25;
    &[data-variant="h1"] {
      letter-spacing: 1.5px;
      font-size: ${rem(30)};
      font-weight: 700;
      @media screen and (min-width: ${theme.breakpoints.large}) {
        font-size: ${rem(42)};
      }
    }
    &[data-variant="h2"] {
      letter-spacing: 1.5px;
      font-size: ${rem(30)};
      font-weight: 700;
      @media screen and (min-width: ${theme.breakpoints.large}) {
        font-size: ${rem(30)};
      }
    }
    &[data-variant="h3"] {
      letter-spacing: 1px;
      font-size: ${rem(22)};
      font-weight: 700;
      @media screen and (min-width: ${theme.breakpoints.large}) {
        font-size: ${rem(22)};
      }
    }
    &[data-variant="h4"] {
      font-weight: 600;
      letter-spacing: 1px;
      font-size: ${rem(14)};
    }
    &[data-variant="h5"] {
      font-weight: 600;
      letter-spacing: 1px;
      font-size: ${rem(12)};
    }
  `
)
