import { CbContact } from "@generated/models/CbContact"
import React, { FC } from "react"
import { Container, CONTENT_BLOCK_MB } from "../Container"
import { Heading } from "../typograhpy/Heading"
import { Text } from "../typograhpy/Text"
import * as styles from "./Contact.styles"
export const Contact: FC<CbContact> = ({ title, subtitle, phone, email }) => {
  return (
    <Container mb={CONTENT_BLOCK_MB}>
      <div className={styles.container}>
        <Heading as="h3" variant="h2" mb="12">
          {title.value}
        </Heading>
        <Text mb="24">{subtitle.value}</Text>
        <div className={styles.buttonContainer}>
          <a
            className={styles.link}
            href={`tel:${phone.value}`}
            title={phone.value}
          >
            {phone.value}
          </a>
          <a
            className={styles.link}
            href={`mailt:${email.value}`}
            title={email.value}
          >
            {email.value}
          </a>
        </div>
      </div>
    </Container>
  )
}
