import { parse, theme } from "@config/theme"
import { css } from "linaria"

export const container = parse(
  { bg: "black", position:  "relative" },
  css`
    z-index: 100;
    top: 0;
  `
)
export const flex = parse(
  {
    display: "flex",
    py: { _: "112", medium: "112", large: "80" },
  },
  css`
    flex-direction: column;

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      flex-direction: row;
      justify-content: space-between;
    }
  `
)

export const logo = parse({
  position: 'absolute',
}, css`
  top: 2rem;
  pointer-events: none;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;

  @media screen and (min-width: ${theme.breakpoints.large}) {
    top: 50%;
    transform: translateY(-50%);
  }
`)

export const address = parse({
  textAlign: {_: 'center', medium: 'left'},
  mb: {_: '24', medium: '0'}
}, css`
  grid-area: address;
  flex: 1;
`)

export const contactDetails = parse({
  mb: {_: '24', medium: '0'}
})

export const contact = parse({
  display: 'flex',
  justifyContent: {_: 'flex-end'},
  alignItems: 'center'
}, css`
  text-align: right;
  flex: 1;
  flex-direction: column;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    flex-direction: row;
  }
`)

export const link = parse({
  display: 'flex',
  justifyContent: {_: 'center', medium: 'flex-end'},
  ml: {_: '16'}
}, css`
  color: white;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: .7;
    }
  }

  svg {
    max-width: 2.25rem;

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      max-width: 2.5rem;
    }
  }
`)